import Home from "./pages/Home";
import ForgotPassword from "./pages/ForgotPassword";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />
  }
]);


export default function App() {
  return (
    <RouterProvider router={router} />
  );
}



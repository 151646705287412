import "../components/Map.css"
import Map from "../components/Map";
import 'leaflet/dist/leaflet.css';


// import MapComponent from "../components/MapComponent";

export default function Home(){
    return (
        <>
            <Map />
        </>
    );
}
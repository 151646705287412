//11/17/22 7:02PM
import React, {useEffect, useState, useRef} from 'react';
import axios from 'axios';
import {
    MapContainer, TileLayer, Marker, useMapEvents, CircleMarker, Circle, Popup,LayersControl,Pane
    , WMSTileLayer
} from "react-leaflet"
// import LoadingSpinner from "./LoadingSpinner";
import LoadingOverlay from 'react-loading-overlay';
import {toast, ToastContainer} from 'react-toastify';
import L from "leaflet";
// import MarkerClusterGroup from '@changey/react-leaflet-markercluster';
import 'leaflet/dist/leaflet.css';
// import '@changey/react-leaflet-markercluster/dist/styles.min.css';
// import "leaflet-loading";
import {Icon} from "leaflet";
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, Row, Col,
    Form,FormGroup,
    FormFeedback,
    Input,
    Label,
    NavItem, NavLink, ButtonGroup, Toast, ToastBody, ToastHeader
} from "reactstrap";

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import {useForm} from 'react-hook-form';

import classnames from 'classnames';
import 'react-toastify/dist/ReactToastify.css';
import {AsyncTypeahead} from "react-bootstrap-typeahead";

const WrappedInput = React.forwardRef((props, ref) => (
    <Input innerRef={ref} {...props} />
));

const colorPallette = {
    0: "#ffffff",
    1: "#a3b4cf",
    2: "#4c668f",
    3: "#18325b"
}


export default function Map({title, name}) {

    let [token,setToken] = useState(0);

    useEffect( () => {
        if(localStorage.getItem("token") !== "" && localStorage.getItem("token") != null){
            const jwtPayload = JSON.parse(window.atob(localStorage.getItem("token").split('.')[1]));

            if(Date.now() <= jwtPayload.exp * 1000){
                setAuthenticated(true);
                return;
            }

            logout();
        }
    },[])

    // Map ref
    const mapRef = useRef();

    // Map state:
    const [loginModal, setLoginModal] = useState({
        showModal: false,
        loading: false,
        error: null
    });

    const [selectedLayerType, setSelectedLayerType] = useState('roadmap');
    const [currentActiveTab, setCurrentActiveTab] = useState('1');
    const [authenticated, setAuthenticated] = useState(false);

    // Toggle active state for Tab
    const toggleLoginModalTab = tab => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    }
    const [activeHouseData, setActiveHouse] = useState(null);
    const [houseData, setHouseData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [reviewSelectionLayer, setReviewSelectionLayer] = useState('nickcross:house_reviews_all');
    const [partialReviewChecked, setIsPartiallReviewChecked] = useState(true);
    const [noReviewChecked, setIsNoReviewChecked] = useState(true);

    // const urlScheme = "http://127.0.0.1:8000/api";
    // const urlScheme = "http://34.27.1.131/api";
    const urlScheme = "https://3for30.crosspointschurch.com/api";
    const nickcross_layer_url = "https://3for30.crosspointschurch.com/geoserver/nickcross/wms?";
    // const nickcross_layer_url = "http://35.208.159.57/geoserver/nickcross/wms"

    const icon = new Icon({
        iconUrl: "/skateboarding.svg",
        iconSize: [25, 25]
    });
    const markerRenderer = L.canvas({padding: 0.5});

    const iconChurch = new Icon({
        iconUrl: "/church-3204.svg",
        iconSize: [35, 35]
    });

    const {
        register,
        setError,
        handleSubmit,
        formState: {errors},
        reset
    } = useForm();

    const {
        register: registerSignUp,
        handleSubmit: handleSubmitSignUp,
        setError: setErrorSignUp,
        formState: {errors: errorsSignUp},
        reset: resetSignUp
    } = useForm();



    const {
        register: registerForgotPassword,
        handleSubmit: handleSubmitForgotPassword,
        setError: setErrorForgotPassword,
        formState: {errors: errosForgotPassword},
        reset: resetForgotPassword
    } = useForm();

      const getReviewColor = (house) => {
        if(house.share && house.care && house.pray){
            return "18325b";
        }

        return "f6a236";
    }

    const isNotEmpty = (str) => {
        return typeof str === 'string' && str.trim() !== '';
    }

    // const filter = (house) => {
    //     if(reviewSelection == "all")
    //         return true;
    //     else if(reviewSelection == "needpray"){
    //         return !isNotEmpty(house.pray);
    //     }
    //     else if(reviewSelection == "needcare"){
    //         return !isNotEmpty(house.care);
    //     }
    //     else if(reviewSelection == "needshare"){
    //         return !isNotEmpty(house.share);
    //     }
    //     else
    //         return  true;
    // }
    //
    // const markersComponent = React.useMemo(() => {
    //     console.log(" data use memo");
    //     let markers = houseData.filter( h => filter(h) ).map(house => (
    //         <CircleMarker
    //             key={house.id}
    //             center={[
    //                 house.latitude,
    //                 house.longitude
    //             ]}
    //             fillOpacity={0.9}
    //             radius={10}
    //             weight={1}
    //             color={`#${getReviewColor(house)}`}
    //             eventHandlers={{
    //                 click: (e) => {
    //                     getByHouseid(house.id);
    //                 },
    //                 mouseover: (e) => {
    //                     e.target.setStyle({fillColor: 'yellow'});
    //                 },
    //                 mouseout: (e) => {
    //                     e.target.setStyle({fillColor: '#18325b'});
    //                 }
    //             }}
    //         />
    //
    //     ));
    //     console.log("end data use memo");
    //     return markers;
    // }, [houseData ,reviewSelection]);




    function openLoginModal() {
        setLoginModal({
            ...loginModal
            , showModal: true
        });
    }

    function closeLoginModal() {
        setLoginModal({
            ...loginModal
            , showModal: false,
            error: null
        });
    }


    function startLoginLoading() {
        setLoginModal({
            ...loginModal
            , loading: true
        });
    }

    function finishLoginLoading() {
        setLoginModal({
            ...loginModal
            , loading: false
        })
    }


    function onLoginFail(method, response) {
        console.log("logging failed with " + method);
        setLoginModal({
            ...loginModal
            , showModal: false,
            error: response
        });
    }

    function afterLoginTabsChange() {
        setLoginModal({
            ...loginModal
            ,
            error: null
        });
    }


    function sendReviews(data) {
        const req = {};
        req.houseId = activeHouseData.id;
        req.review = data;
        axios.post(urlScheme + '/sendreviews/', req, {
            headers: {
                'Authorization': `Bearer  ${localStorage.getItem("token")}`
            }
        }).then(function (response) {
            setActiveHouse(null);
            setToken(++token) ;
        })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                setIsLoading(false);
            });
    }

    const loginSubmit = (data) => {

        const value = data.username || "";
        // data['username'] = value.toLowerCase();

        const req = data;
        setIsLoading(true);
        axios.post(urlScheme + '/token/', req)
            .then(function (response) {
                setAuthenticated(true);
                localStorage.setItem("token", response.data.access);
                reset();
                closeLoginModal();
            })
            .catch(function (error) {
                setError("passwordwrong", {type: 'custom', message: error.response.data.detail}, {shouldFocus: false});
                console.log(error);
            })
            .then(function () {
                setIsLoading(false);
            });
    };

    const getByHouseid = (id) => {
        setActiveHouse(null);
        setIsLoading(true);
        axios.get(urlScheme + '/selecthouse', {
            params: {house_id: id}
        })
            .then(function (response) {
                if (200 === response.status) {
                    if (response.data) {
                        // debugger;
                        setActiveHouse({...response.data.house, ...response.data.reviews});
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                setIsLoading(false);
            });
    }

    function logout() {
        setAuthenticated(false);
        localStorage.removeItem("token");
    }

    const signUpSubmit = (req) => {

        const value = req.username || "";
        // req['username'] = value.toLowerCase();

        setIsLoading(true);

        axios.post(urlScheme + '/register/', req)
            .then(function (response) {
                resetSignUp();
                toast.info("Sign up successful kindly check your email for verification");
                closeLoginModal();
            })
            .catch(function (error) {
                const errors = error.response.data;
                Object.keys(errors).forEach(function (key) {
                    setErrorSignUp(key, {type: 'custom', message: errors[key]}, {shouldFocus: true});
                })
            })
            .then(function () {
                setIsLoading(false);
            });


    };


    const forgotPasswordSubmit = (data) => {
        setIsLoading(true);
        const req = data;

        axios.post(urlScheme + '/password_reset/', req)
            .then(function (response) {
                resetForgotPassword();
                toast.info("Password reset request send successfully to your registered email. Please check your email for verification");
                closeLoginModal();
            })
            .catch(function (error) {
                const errors = error.response.data;
                Object.keys(errors).forEach(function (key) {
                    setErrorForgotPassword(key, {type: 'custom', message: errors[key]}, {shouldFocus: true});
                })
            })
            .then(function () {
                setIsLoading(false);
            });
    }






    function ChurchMarker() {

        const map = useMapEvents({
            click: (e) => {
                const { lat, lng } = e.latlng;

                // if(map.getZoom() < 17){
                //     toast.warn("Zoom in further to indentify");
                //     return;
                // }

                axios.get(urlScheme + '/search' , { params :{lat,lng}
                }).then(function (response) {
                    if (200 === response.status) {
                        if(response.data ){
                            // debugger;
                            setActiveHouse({...response.data.house, ...response.data.reviews });
                        }
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                    })
                    .then(function () {
                        setIsLoading(false);
                    });

            },
            zoomend: () => {
                console.log(map.getZoom());
                if (map.getZoom() < 19) {
                    setActiveHouse(null);
                }
                //updateData(map);
            },
            dragend: () => {
                if (!map) return;
                //  updateData(map);
            }
        })

        return (
            <Marker position={{lat: 39.00540617877967, lng: -94.7631831906815}}
                    icon={iconChurch}>
            </Marker>
        )
    }

    const LoginButton = () => {
        const ref = React.useRef(null);
        useEffect(() => {
            if (ref.current) {
                L.DomEvent.disableClickPropagation(ref.current);
            }
        });

        return (
            <div ref={ref}>
                <Button onClickCapture={(e) => {
                    e.preventDefault();
                    setActiveHouse(
                        null
                    );

                    openLoginModal();
                }}>Login</Button>
            </div>
        )
    }

    const LayerLegend= () => {
        const ref = React.useRef(null);
        useEffect(() => {
            if (ref.current) {
                L.DomEvent.disableClickPropagation(ref.current);
            }
        });

        return (
            <div ref={ref} style={{width:150}}>
                {
                    reviewSelectionLayer==="nickcross:house_reviews_all"?
                    <div className={"review-filter p-1 rounded shadow-lg mt-3"}>
                        <div className={"mb-2"}>
                            <Label check className={"fw-bold custom-control-label"}>
                                0 Tasks Completed
                            </Label>
                            <span className="float-end px-2 py-2 ms-1 mr-1" style={{backgroundColor:colorPallette[0], border:"1px solid black"}}></span>
                        </div>
                        <div className={"mb-2"}>
                            <Label className={"fw-bold custom-control-label"} check>
                                1 Task Completed
                            </Label>
                            <span className="float-end px-2 py-2 ms-1 mr-1" style={{backgroundColor:colorPallette[1], border:"1px solid black"}}></span>
                        </div>
                        <div className={"mb-2"}>
                            <Label check className={"fw-bold custom-control-label"}>
                                2 Tasks Completed
                            </Label>
                            <span className="float-end px-2 py-2 ms-1 mr-1" style={{backgroundColor:colorPallette[2], border:"1px solid black"}}></span>
                        </div>
                        <div className={"mb-2"}>
                            <Label check className={"fw-bold custom-control-label"}>
                                3 Tasks Completed
                            </Label>
                            <span className="float-end px-2 py-2 ms-1 mr-1r" style={{backgroundColor:colorPallette[3], border:"1px solid black"}}></span>
                        </div>
                    </div>
                    :
                    <div className={"review-filter p-1 rounded shadow-lg mt-3"}>
                        <div className={"mb-2"}>
                            <Label check className={"fw-bold custom-control-label"}>
                                Uncompleted
                            </Label>
                            <span className="float-end px-2 py-2 ms-1 mr-1r" style={{backgroundColor:colorPallette[0], border:"1px solid black"}}></span>
                        </div>
                        <div className={"mb-2"}>
                            <Label check className={"fw-bold custom-control-label"}>
                                Completed
                            </Label>
                            <span className="float-end px-2 py-2 ms-1 mr-1" style={{backgroundColor:colorPallette[3], border:"1px solid black"}}></span>
                        </div>

                    </div>
                }
                
            </div>
        )
    }

    const SearchBar = () => {
        const BarStyling = {width:"20rem",background:"#FFFFFF", border:"none", padding:"0.5rem"};
        const ref = React.useRef(null);
        useEffect(() => {
            if (ref.current) {
                L.DomEvent.disableClickPropagation(ref.current);
            }
        });

        const [isTypeSearchLoading, setIsTypeSearchLoading] = useState(false);
        const [options, setOptions] = useState([]);
        const filterBy = () => true;

        const handleChange = (selected) => {

            const {id} = selected[0];
            
            // /** Zoom to bounds */
            // const {latitude, longitude} = selected[0];
            // const bounds = [
            //     [latitude -0.0001 , longitude -0.0001],
            //     [latitude +0.0001, longitude +0.0001]
            // ];
            //
            // mapRef.current.flyToBounds(bounds,{duration:1});

            // axios.get(urlScheme + '/search' , { params :{lat:latitude,lng:longitude}
            // }).then(function (response) {
            //     if (200 === response.status) {
            //         if(response.data ){
            //             // debugger;
            //             setActiveHouse({...response.data.house, ...response.data.reviews });
            //         }
            //     }
            // })
            //     .catch(function (error) {
            //         console.log(error);
            //     })
            //     .then(function () {
            //         setIsLoading(false);
            //     });

            setActiveHouse(null);
            setIsLoading(true);
            axios.get(urlScheme + '/selecthouse', {
                params: {house_id: id}
            })
                .then(function (response) {
                    if (200 === response.status) {
                        if (response.data) {
                            // debugger;
                            setActiveHouse({...response.data.house, ...response.data.reviews});

                            const {latitude, longitude} = response.data.house;
                            const bounds = [
                                [latitude -0.0001 , longitude -0.0001],
                                [latitude +0.0001, longitude +0.0001]
                            ];

                            mapRef.current.flyToBounds(bounds,{duration:1});
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
                .then(function () {
                    setIsLoading(false);
                });

        }

        const handleSearch = (query) => {
            setIsTypeSearchLoading(true);

            axios.get(urlScheme + '/searchaddress' , { params :{query}
            }).then(function (response) {
                // debugger;
                if (200 === response.status) {
                    if(response.data ){
                        setOptions(response.data.results);
                    }
                }
            })
                .catch(function (error) {
                    console.log(error);
                })
                .then(function () {
                    setIsTypeSearchLoading(false);
                })
        };

        return (
            <div ref={ref} className={"w-10"}>
                <AsyncTypeahead
                    filterBy={filterBy}
                    id="async-example"
                    onChange={handleChange}
                    isLoading={isTypeSearchLoading}
                    labelKey="street_address"
                    minLength={3}
                    onSearch={handleSearch}
                    options={options}
                    placeholder="Search address..."
                    renderMenuItemChildren={(option) => (
                            <span>{option.post_office}</span>
                    )}
                />
            </div>);
    }

    const LayerFilter = () => {
        const ref = React.useRef(null);
        useEffect(() => {
            if (ref.current) {
                L.DomEvent.disableClickPropagation(ref.current);
            }
        });

        const  setLayer = (layer) => {
            setReviewSelectionLayer(layer);
            setToken(++token) ;
            setActiveHouse(null);
        }

        return (
            <div ref={ref}>
                <div className={"review-filter p-1 rounded shadow-lg"}>
                    <FormGroup check>
                        <Input
                            className={"custom-control-input"}
                            checked={reviewSelectionLayer == "nickcross:house_reviews_all"}
                            onChange={() =>  setLayer('nickcross:house_reviews_all')}
                            type="radio"
                            name="reviewFilterRadio"
                        />
                        <Label className={"fw-bold custom-control-label"} check>
                            All Data
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Input
                            checked={reviewSelectionLayer == "nickcross:house_reviews_needpray"}
                            className={"custom-control-input"}
                            onChange={() =>  setLayer('nickcross:house_reviews_needpray')}
                            type="radio"
                            name="reviewFilterRadio"
                        />
                        <Label check className={"fw-bold custom-control-label"}>
                            PRAY
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Input
                            checked={reviewSelectionLayer == "nickcross:house_reviews_needshare"}
                            className={"custom-control-input"}
                            onChange={() =>  setLayer('nickcross:house_reviews_needshare')}
                            type="radio"
                            name="reviewFilterRadio"
                        />
                        <Label className={"fw-bold custom-control-label"} check>
                            SHARE
                        </Label>
                    </FormGroup>

                    <FormGroup check>
                        <Input
                            checked={reviewSelectionLayer == "nickcross:house_reviews_needcare"}
                            className={"custom-control-input"}
                            onChange={() =>  setLayer('nickcross:house_reviews_needcare')}
                            type="radio"
                            name="reviewFilterRadio"
                        />
                        <Label check className={"fw-bold custom-control-label"}>
                            CARE
                        </Label>
                    </FormGroup>
                </div>
            </div>
        )
    }

    function GoogleMapSelection() {

        const ref = React.useRef(null);
        useEffect(() => {
            if (ref.current) {
                L.DomEvent.disableClickPropagation(ref.current);
            }
        });

        return (<div ref={ref}>
            <ButtonGroup>
                <Button onClick={() => setSelectedLayerType("satellite")}
                        active={selectedLayerType === "satellite"}>Satellite</Button>
                <Button onClick={() => setSelectedLayerType("roadmap")}
                        active={selectedLayerType === "roadmap"}>RoadMap</Button>
            </ButtonGroup>
        </div>);
    }

    return (
        <>
            {/* {isLoading ? <LoadingSpinner /> : MapCont} */}
            <ToastContainer/>
            <LoadingOverlay
                active={isLoading}
                spinner
                text='Loading ...'
            >
                <MapContainer
                    ref={mapRef}
                    className="markercluster-map"
                    center={{lat: 39.005235, lng: -94.7635586}}
                    zoom={13}
                    scrollWheelZoom={true}

                    // loadingControl={true}
                    whenReady={() => {

                        // setIsLoading(true);
                        // console.log("getting data");
                        // axios.get(urlScheme + '/housesdata/')
                        //     .then(function (response) {
                        //         if (200 === response.status) {
                        //             setHouseData(response.data.results);
                        //             console.log("set data");
                        //         }
                        //     })
                        //     .catch(function (error) {
                        //         console.log(error);
                        //     })
                        //     .then(function () {
                        //         setIsLoading(false);
                        //     });

                    }}>
                    <ReactLeafletGoogleLayer apiKey='AIzaSyBjBNlK5_NX9XFW77YaRj9Av8fcN2sIRx4' key={selectedLayerType}
                                             type={selectedLayerType}/>

                    <Pane name="yellow-rectangle" style={{ zIndex: 499 }}>
                        <WMSTileLayer url={nickcross_layer_url+ "?t="  + token  }
                                      maxNativeZoom={18}
                                      maxZoom={21}
                                      opacity={1} transparent={true}
                                      layers={reviewSelectionLayer }
                                      key={token}
                                      srs="EPSG:4326"
                                      format="image/png">
                        </WMSTileLayer>
                    </Pane>



                    {
                        activeHouseData && (
                            <Popup
                                position={[
                                    activeHouseData.latitude,
                                    activeHouseData.longitude
                                ]}
                                onClose={() => {
                                    setActiveHouse(null);
                                }}
                            >

                                <table className="table table-striped">
                                    <tbody>
                                    <tr>
                                        <td><b>Surname</b></td>
                                        <td>{activeHouseData.surname}</td>
                                    </tr>
                                    <tr>
                                        <td><b>House Number</b></td>
                                        <td>{activeHouseData.house_number}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Given Name</b></td>
                                        <td>{activeHouseData.primary_given_name}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Street Address</b></td>
                                        <td>{activeHouseData.street_address}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Post office</b></td>
                                        <td>{activeHouseData.post_office}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Religion</b></td>
                                        <td>{activeHouseData.religion}</td>
                                    </tr>
                                    </tbody>
                                </table>

                                {authenticated && (
                                    <ButtonGroup className={"btn-group d-flex"}>
                                        <Button className={`w-100 ${(activeHouseData.pray || 0) > 0 ? "active" : ""}`}
                                                onClick={() => {
                                                    sendReviews("pray");
                                                }}>Pray</Button>
                                        <Button className={`w-100 ${(activeHouseData.care || 0) > 0 ? "active" : ""}`}
                                                onClick={() => {
                                                    sendReviews("care");
                                                }}>Care</Button>
                                        <Button className={`w-100 ${(activeHouseData.share || 0) > 0 ? "active" : ""}`} onClick={() => {
                                            sendReviews("share");
                                        }}>Share</Button>
                                    </ButtonGroup>
                                )}
                                {!authenticated && (
                                    <div>
                                        <br/>
                                        <h3 style={{textAlign: "center"}}>Login to add action</h3>
                                    </div>

                                )}
                            </Popup>
                        )
                    }

                    <Circle fillOpacity={0} opacity={0.5}
                            center={{lat: 39.00540617877967, lng: -94.7631831906815}} radius={8046.72}/>

                    <div className={"leaflet-bottom leaflet-left "}>
                        <div className="leaflet-control leaflet-bar">
                            <GoogleMapSelection/>
                        </div>
                    </div>
                    <div className="leaflet-top leaflet-left mt-6">
                        <div className="leaflet-bar leaflet-control">
                            <LayerFilter/>
                            <LayerLegend />
                        </div>
                    </div>

                    <div className={"leaflet-control-container"}>
                        <div className={"leaflet-top leaflet-right"}>
                            <div className="leaflet-control leaflet-bar">
                                {!authenticated && (
                                    <LoginButton/>)}
                                {authenticated && (
                                    <Button onClickCapture={(e) => {
                                        setActiveHouse(
                                            null
                                        );
                                        e.stopPropagation();
                                        e.nativeEvent.stopImmediatePropagation();
                                        logout();
                                    }}>Logout</Button>)}
                            </div>
                        </div>
                    </div>
                    <div className={"leaflet-control-container"}>
                        <div className={"leaflet-top leaflet-right"}>
                            <div className="leaflet-control me-10">
                                <SearchBar />
                            </div>
                        </div>
                    </div>
                    <ChurchMarker/>
                </MapContainer>
            </LoadingOverlay>
            <div>
                <Modal isOpen={loginModal.showModal} toggle={closeLoginModal} className={"modal-dialog-centered"}>
                    <ModalBody>
                        <TabContent activeTab={currentActiveTab}>
                            <TabPane tabId="1">
                                <Row>
                                    <Col sm="12">
                                        <div className="logo mb-3">
                                            <div className="col-md-12 text-center">
                                                <h1>Login</h1>
                                            </div>
                                        </div>
                                        <br/>
                                        <Form onSubmit={handleSubmit(loginSubmit)}>
                                            <Row>
                                                <Label for="username" column lg={3}>Username</Label>
                                                <Col>
                                                    <WrappedInput
                                                        type="text"
                                                        name="username"
                                                        id="username"
                                                        invalid={errors?.username}
                                                        {...register("username", {required: "User name is required"})}
                                                    />
                                                    {errors?.username && (
                                                        <FormFeedback> {errors?.username?.message}</FormFeedback>
                                                    )}
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row>
                                                <Label for="password" column lg={3}>Password</Label>
                                                <Col>
                                                    <WrappedInput
                                                        type="password"
                                                        name="password"
                                                        id="password"
                                                        invalid={errors?.password}
                                                        {...register("password", {required: "Password is required"})}
                                                    />
                                                    {errors?.password && (
                                                        <FormFeedback> {errors?.password?.message}</FormFeedback>
                                                    )}
                                                    <WrappedInput
                                                        type="hidden"
                                                        name="passwordwrong"
                                                        id="passwordwrong"
                                                        invalid={errors?.passwordwrong}
                                                        {...register("passwordwrong")}
                                                    />
                                                    {errors?.passwordwrong && (
                                                        <FormFeedback> {errors?.passwordwrong?.message}</FormFeedback>
                                                    )}
                                                </Col>
                                                <div className="text-center mt-3">
                                                    <Button type="submit" className={"w-75 p-2"}>LOGIN</Button>
                                                </div>
                                            </Row>
                                        </Form>

                                        <div className="form-group mt-2">
                                            <p className="text-center">Don't have account? <a href="javascript:void(0)"
                                                                                              onClick={(e) => {
                                                                                                  e.stopPropagation();
                                                                                                  e.nativeEvent.stopImmediatePropagation();
                                                                                                  toggleLoginModalTab('2')
                                                                                              }}>Sign up
                                                here</a></p>
                                        </div>
                                        <div className="form-group mt-2">
                                            <p className="text-center">Forgot your password? <a href="javascript:void(0)"
                                                                                              onClick={(e) => {
                                                                                                  e.stopPropagation();
                                                                                                  e.nativeEvent.stopImmediatePropagation();
                                                                                                  toggleLoginModalTab('3')
                                                                                              }}>Reset your password
                                                here</a></p>
                                        </div>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <div className="logo mb-1">
                                    <div className="col-md-12 text-center">
                                        <h1>SignUp</h1>
                                    </div>
                                </div>
                                <Form onSubmit={handleSubmitSignUp(signUpSubmit)}>
                                    <br/>
                                    <Row className='mb-3'>
                                        <Label for="username" column lg={3}>Username</Label>
                                        <Col>
                                            <WrappedInput
                                                type="text"
                                                name="username"
                                                id="username"
                                                invalid={errorsSignUp?.username}
                                                {...registerSignUp("username", {required: "username  is required"})}
                                            />
                                            {errorsSignUp?.username && (
                                                <FormFeedback> {errorsSignUp?.username?.message}</FormFeedback>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className='mb-3'>
                                        <Label for="password" column lg={3}>Password</Label>
                                        <Col>
                                            <WrappedInput
                                                type="password"
                                                name="password"
                                                id="password"
                                                placeholder="********"
                                                invalid={errorsSignUp?.password}
                                                {...registerSignUp("password", {required: "Password  is required"})}
                                            />
                                            {errorsSignUp?.password && (
                                                <FormFeedback> {errorsSignUp?.password?.message}</FormFeedback>
                                            )}
                                        </Col>
                                    </Row>

                                    <Row className='mb-3'>
                                        <Label for="password2" column lg={3}>Retype Password</Label>
                                        <Col>
                                            <WrappedInput
                                                type="password"
                                                name="password2"
                                                id="password2"
                                                placeholder="********"
                                                invalid={errorsSignUp?.password2}
                                                {...registerSignUp("password2", {required: "Password  is required"})}
                                            />
                                            {errorsSignUp?.password2 && (
                                                <FormFeedback> {errorsSignUp?.password2?.message}</FormFeedback>
                                            )}
                                        </Col>
                                    </Row>

                                    <Row className='mb-3'>
                                        <Label for="email" column lg={3}>Email</Label>
                                        <Col>
                                            <WrappedInput
                                                type="email"
                                                name="email"
                                                id="email"
                                                invalid={errorsSignUp?.email}
                                                {...registerSignUp("email", {required: "Email  is required"})}
                                            />
                                            {errorsSignUp?.email && (
                                                <FormFeedback> {errorsSignUp?.email?.message}</FormFeedback>
                                            )}

                                        </Col>
                                    </Row>

                                    <Row className='mb-3'>
                                        <Label for="first_name" column lg={3}>First Name</Label>
                                        <Col>
                                            <WrappedInput
                                                type="text"
                                                name="first_name"
                                                id="first_name"
                                                invalid={errorsSignUp?.first_name}
                                                {...registerSignUp("first_name", {required: "First name  is required"})}
                                            />
                                            {errorsSignUp?.first_name && (
                                                <FormFeedback> {errorsSignUp?.first_name?.message}</FormFeedback>
                                            )}
                                        </Col>
                                    </Row>

                                    <Row className='mb-3'>
                                        <Label for="last_name" column lg={3}>Last Name</Label>
                                        <Col>
                                            <WrappedInput
                                                type="text"
                                                name="last_name"
                                                id="last_name"
                                                invalid={errorsSignUp?.last_name}
                                                {...registerSignUp("last_name", {required: "Last name  is required"})}
                                            />
                                            {errorsSignUp?.last_name && (
                                                <FormFeedback> {errorsSignUp?.last_name?.message}</FormFeedback>
                                            )}
                                        </Col>
                                        <div className="text-center mt-3">
                                            <Button type="submit" className={"w-75 p-2"}>SIGNUP</Button>
                                        </div>
                                    </Row>
                                </Form>
                                <div className="form-group mt-2">
                                    <p className="text-center"><a href="javascript:void(0)"
                                                                  onClick={(e) => {
                                                                      e.preventDefault();
                                                                      toggleLoginModalTab('1')
                                                                  }}>Already have an account?</a></p>
                                </div>
                            </TabPane>
                            <TabPane tabId="3">
                            <div className="logo mb-1">
                                    <div className="col-md-12 text-center">
                                        <h3>Reset Password</h3>
                                    </div>
                                </div>
                                <Form onSubmit={handleSubmitForgotPassword(forgotPasswordSubmit)}>
                                    <Row className='mb-3'>
                                        <p>Please Enter your registered email</p>
                                        <Label for="email" column lg={3}>Email</Label>
                                        <Col>
                                            <WrappedInput
                                                type="email"
                                                name="email"
                                                id="email"
                                                invalid={errosForgotPassword?.email}
                                                {...registerForgotPassword("email", {required: "Email  is required"})}
                                            />
                                            {errosForgotPassword?.email && (
                                                <FormFeedback> {errosForgotPassword?.email?.message}</FormFeedback>
                                            )}
                                        </Col>
                                    </Row>

                                    <div className="text-center mt-3">
                                        <Button type="submit" className={"w-75 p-2 mt-3"}>Reset password</Button>
                                    </div>
                                </Form>
                            </TabPane>
                        </TabContent>
                    </ModalBody>
                </Modal>
            </div>

        </>
    )
}



import styles from '../styles/pages/ForgotPassword.module.css';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useEffect,useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


const ForgotPassword = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [token, setToken] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();

    /** Modal */
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const toggleSuccessModal = () => setIsSuccessModalOpen(!isSuccessModalOpen);

    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const toggleErrorModal = () => setIsErrorModalOpen(!isErrorModalOpen);


    const handleChange = (e) => {
        setPassword(e.target.value);
    }

    

    useEffect(()=>{
        if(searchParams.get('q')){
            setToken(searchParams.get("q"));
        }
        
    },[searchParams]);


    const handleSubmit = () => {
        //console.log(`submitted token: ${token}, ${password}`)
        // console.log(process.env.REACT_APP_API_URL);

        fetch(process.env.REACT_APP_API_URL+"/password_reset/confirm/",{
            method:'POST',
            headers:{
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: token,
                password: password
            })
        }).then((res)=>{
            // alert("Password has been reset");
            if (res.status===200){
                toggleSuccessModal();
            }
            else {
                toggleErrorModal();
            }
        }).catch(()=>{
            toggleErrorModal();
        }).finally(()=>{
            //navigate("/");
        })
    }

    return ( 
    <div className={styles.container}>
        <div className={styles.form}>
            
                <h3>Please enter your new password</h3>
                <input style={inlineStyles.inputPassword} type="password" onChange={handleChange}/>

                <button 
                className={styles.submit}
                onClick={handleSubmit}>Submit</button>
        </div>

        {/* Success Modal */}
        <Modal isOpen={isSuccessModalOpen} toggle={toggleSuccessModal}>
        <ModalHeader toggle={toggleSuccessModal}>Password Reset Successful</ModalHeader>
        <ModalBody>
          Your password has been reset successfully. Please return to the home page to login.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={()=>{
            toggleSuccessModal();
            navigate("/");
          }}>
            Okay
          </Button>
        </ModalFooter>
      </Modal>

      {/* Error Modal */}
      <Modal isOpen={isErrorModalOpen} toggle={toggleErrorModal}>
        <ModalHeader toggle={toggleErrorModal}>Something went wrong!</ModalHeader>
        <ModalBody>
          Unable to reset the password. Please contact your administrator for support
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={()=>{
            toggleErrorModal();
            navigate("/");
          }}>
            Okay
          </Button>
        </ModalFooter>
      </Modal>
        
    </div> );
}
 
export default ForgotPassword;

const inlineStyles = {
    inputPassword: {
        width: '100%',
        height:50,
        marginTop: 15,
        border: '1px solid lightgrey',
    }
}